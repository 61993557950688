

























































.modal-content {
  width: 100%;
  border-radius: 0;
}

/deep/ .v--modal-overlay .v--modal-box {
  overflow: visible;
  outline: 30px solid red;
}

.modal-body {
  min-height: 240px;
  max-height: 600px;
  padding: 1rem;
  overflow: auto;

  .comments {
    max-height: 300px;
    overflow: auto;

    .comment {
      width: 100%;
      margin-bottom: 2rem;
      border-left: 2px #6259ca solid;
      padding: 0 15px;

      .creator {
        font-weight: 700;
        &:before {
          content: '\2014 \00A0';
        }
      }
    }
  }

  .form-group {

  }

  @media screen and (max-width: 767.98px) {
    height: 100vh;
    max-height: calc(100vh - 74px);
  }

  .sub-title {
    margin-bottom: 1rem;
  }
  .collapsed-block {
    margin-bottom: 0.75rem;
  }
}

.modal-footer {
  padding: .75rem;
}
