









































.actionsCellRenderer {
  /deep/ .btn {
    margin-right: 0.375rem;
    padding: 0.28rem 0.28rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
