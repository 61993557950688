



























.chart {
  &__header {
    display: flex;
    justify-content: space-between;
    color: #7F869B;
    margin-bottom: 14px;

    .h5 {
      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
  }

  &__title {
    font-size: 17px;
    line-height: 20px;
    color: #7F869B;
  }

  &__btns {
    display: flex;
    padding: 0 5px;
    text-transform: lowercase;

    @media screen and (max-width: 1200px) {
      padding: 0;
    }
  }

  &__divider {
    margin: 0 4px;

    &:last-child {
      display: none;
    }
  }

  &__btn {
    cursor: pointer;
    text-decoration: underline;

    @media screen and (max-width: 500px) {
      font-size: 12px;
    }

    &._active {
      cursor: default;
      text-decoration: none;
    }
  }

  /deep/ &__canvas {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /deep/ canvas {
    max-width: 200%;
    width: auto !important;
    max-height: 194px;
    margin: auto;
    height: auto !important;
  }
}
