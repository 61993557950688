







.copy-btn {
  cursor: pointer;
  display: inline-block;
}
